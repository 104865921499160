<template>
  <section class="responsible-gaming py-4 py-sm-6">
    <div class="bs-container">
      <div class="bs-row">
        <div class="bs-col-md-6 mx-md-auto">
          <v-card class="bs-shadow-none">
            <div class="pa-6 pa-md-12">
              <ResponsibleGaming />
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ResponsibleGaming from "@/components/ResponsibleGaming";

export default {
  components: { ResponsibleGaming },
};
</script>

<style scoped>
.responsible-gaming .v-card {
  color: #fff !important;
  background: rgb(0, 115, 211) !important;
  background: radial-gradient(
    circle,
    rgba(0, 115, 211, 1) 0%,
    rgba(1, 105, 190, 1) 100%
  ) !important;
}
</style>
